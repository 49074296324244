import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import "./css/Landing.css";
import Navbar from './widgets/Navbar';
import Card from "./widgets/Card";
import { filterOptions } from "./constants.js";
import { ContractKit } from '@wharfkit/contract';
import { getDynamicContent } from "./funcs/dynamicTexts.js";
import { trackEvent } from '../analytics.js';
import Foot from './widgets/Footer.js';

const NFT = ({
    session,
    handleLogout,
    balance,
    filteredAssets,
    schemaFilter,
    setSchemaFilter,
    onSelect,
    selectedAssets,
    assets,
    setTransactionHash,
    setShowPopup,
    setSelectedAssets,
    refreshAssets,
    visibleAssets,
    setVisibleAssets
}) => {
    const [activeFilter, setActiveFilter] = useState(null);
    const [availableSchemas, setAvailableSchemas] = useState([]);
    const [displayedAssets, setDisplayedAssets] = useState([]);
    const [hasFarmingLicense, setHasFarmingLicense] = useState(false);
    const { filterType } = useParams();
    const navigate = useNavigate();

    const updateAvailableSchemas = useCallback((filterKey) => {
        const allowedTemplates = filterOptions[filterKey] || [];
        const filtered = filteredAssets.filter(asset =>
            allowedTemplates.includes(asset.template.template_id)
        );
        const uniqueSchemas = [...new Set(filtered.map(asset => asset.schema.schema_name))];
        setAvailableSchemas(uniqueSchemas);
    }, [filteredAssets]);

    const applyFilter = useCallback((filterKey) => {
        setActiveFilter(filterKey);
        setVisibleAssets(12);
        updateAvailableSchemas(filterKey);

        const allowedTemplates = filterOptions[filterKey] || [];
        const filtered = filteredAssets.filter(asset => {
            const isAllowedTemplate = allowedTemplates.includes(asset.template.template_id);
            const isVegkingdomAsset = asset.schema?.schema_name === "vegkingdom";
            const canShowVegkingdom = !(filterKey === "farm" && isVegkingdomAsset && !hasFarmingLicense);
            return isAllowedTemplate && canShowVegkingdom;
        });

        setDisplayedAssets(filtered);
        //setSchemaFilter(null); // Reset schema filter when main filter changes
    }, [filteredAssets, updateAvailableSchemas, hasFarmingLicense, setVisibleAssets]);

    useEffect(() => {
        if (schemaFilter) {
            // Filter assets by schema when schemaFilter changes
            setDisplayedAssets(prevDisplayed =>
                prevDisplayed.filter(asset => asset.schema.schema_name === schemaFilter)
            );
        } else if (activeFilter) {
            // Reset to active filter's assets when schemaFilter is cleared
            const allowedTemplates = filterOptions[activeFilter] || [];
            const resetAssets = filteredAssets.filter(asset =>
                allowedTemplates.includes(asset.template.template_id)
            );
            setDisplayedAssets(resetAssets);
        }
    }, [schemaFilter, activeFilter, filteredAssets]);

    const handleSchemaChange = (e) => {
        setSchemaFilter(e.target.value); // Update schema filter value
    };

    const clearFilter = useCallback(() => {
        setActiveFilter(null);
        setVisibleAssets(12);
        setAvailableSchemas([]);
        setDisplayedAssets([]);
        setSelectedAssets([]);
        navigate('/');
    }, [navigate, setVisibleAssets, setSelectedAssets]);

    const applyFilterAndNavigate = (filterKey) => {
        applyFilter(filterKey);
        navigate(`/nft/${filterKey}`);
    };

    useEffect(() => {
        const matchingAssets = assets.filter(asset => asset.template?.template_id === "374415");
        setHasFarmingLicense(matchingAssets.length > 0);
        if (filterType) {
            applyFilter(filterType);
        } else {
            clearFilter();
        }
    }, [assets, filterType, applyFilter, clearFilter]);

    const handleDepositNFTs = async () => {
        const contractKit = new ContractKit({ client: session.client });
        const address = activeFilter === "staking" ? "pixelstaking" : "pixeltycoons";

        try {
            const contract = await contractKit.load("atomicassets");
            const action = contract.action("transfer", {
                from: session.actor,
                asset_ids: selectedAssets,
                memo: activeFilter,
                to: address,
            });
            const result = await session.transact({ action });
            const txHash = result.response.transaction_id;
            setTransactionHash(txHash);
            const transactionLink = `https://waxblock.io/transaction/${txHash}`;
            setShowPopup(true, transactionLink);
            setSelectedAssets([]);
            await refreshAssets();
            trackEvent('deposit-stake', activeFilter, 'Success', session.actor);
        } catch (error) {
            console.error("Transaction failed", error);
            trackEvent('deposit-stake', activeFilter, 'Fail', session.actor);
        }
    };

    return (
        <div className="Landing">
            <Navbar handleLogout={handleLogout} session={session} balance={balance} />
            <div className="transfer-buttons">
                {activeFilter ? (
                    <div>
                        <div className="return-deposit-stake-button">
                            <div className="schema-filter">
                                <label htmlFor="schema-select">Filter by Schema:</label>
                                <select
                                    id="schema-select"
                                    value={schemaFilter || ''}
                                    onChange={handleSchemaChange}
                                >
                                    <option value="">All</option>
                                    {availableSchemas.map((schema, index) => (
                                        <option key={index} value={schema}>
                                            {schema}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button className="return-button" onClick={clearFilter}>
                                Return
                            </button>
                            <button className="deposit-button" onClick={handleDepositNFTs}>
                                {activeFilter === "staking" ? "Stake" : "Deposit"}
                            </button>
                        </div>
                        <div className="dynamic-content">
                            {getDynamicContent(activeFilter)}
                        </div>
                    </div>
                ) : (
                    <div className="niche-buttons">
                        {["farm", "ca", "magic", "ptfi", "blend", "staking"].map((key, index) => (
                            <button
                                key={index}
                                className="transfer-button"
                                onClick={() => applyFilterAndNavigate(key)}
                            >
                                {`${key.toUpperCase()} Deposit`}
                            </button>
                        ))}
                    </div>
                )}
            </div>
            <div className="Landing-Container">
                <div className="assets-grid-dashboard">
                    {displayedAssets.slice(0, visibleAssets).map(asset => (
                        <Card
                            key={asset.asset_id}
                            asset={asset}
                            onSelect={onSelect}
                            isSelected={selectedAssets.includes(asset.asset_id)}
                        />
                    ))}
                </div>
                {visibleAssets < displayedAssets.length && (
                    <button className="load-more" onClick={() => setVisibleAssets(prev => prev + 12)}>
                        Load More Assets
                    </button>
                )}
            </div>
            <Foot />
        </div>
    );
};

export default NFT;
