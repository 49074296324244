import {
    Link,
    //useLocation 
} from 'react-router-dom';
import './css/Navbar.css';
//import waxLogo from './assets/wax.png';
import ncLogo from './assets/logo.png';
import renderBalances from '../funcs/renderTokens.js';  // Import the function

const Navbar = ({ session, balance, handleLogout }) => {
    // const location = useLocation(); // Get the current route

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <Link to="/" className="navbar-logo">
                    <img src={ncLogo} alt="PT Logo" width="40" height="40" style={{ marginRight: '5px' }} />
                    PixelTycoons
                </Link>
            </div>

            <div className="navbar-center">
                <a className='navbar-link-a' href="https://nfthive.io/drops?collection=pixeltycoons" target="_blank" rel="noopener noreferrer">
                    <p className='navbar-link'>
                        Drops
                    </p>
                </a>
            </div>

            <div className="navbar-right">
                <div className='user-info-bals'>
                    <h4>{String(session.actor)}</h4>

                    {renderBalances(balance)}

                </div>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
        </nav>
    );
};


export default Navbar;
