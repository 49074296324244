import { ContractKit } from '@wharfkit/contract';

export const fetchBalance = async (session) => {
    if (!session) return null;

    try {
        const contractKit = new ContractKit({ client: session.client });

        // Load the two contracts
        const wax = await contractKit.load("eosio.token");
        const ptTokens = await contractKit.load("pixeltbanker");
        const ptbanker = await ptTokens.table("accounts", session.actor).query()
        // Fetch balances
        const [waxBal, ptBal] = await Promise.all([
            wax.table("accounts", session.actor).get(0),
            ptbanker.next()
        ]);
        const pageBal = ptBal[0]
        const pinvBal = ptBal[1]
        // Check if balances were found
        return {
            wax: waxBal ? waxBal.balance.value.toFixed(2) : '0.00', // Return '0.0000' if not found
            pinv: pinvBal ? pinvBal.balance.value.toFixed(2) : '0.00', // Return '0.0000' if not found
            page: pageBal ? pageBal.balance.value.toFixed(2) : '0.00' // Return '0.0000' if not found
        };
    } catch (error) {
        console.error('Error fetching balance:', error);
        return { wax: '0.0000', pinv: '0.0000', page: '0.0000' }; // Return default values on error
    }
};

export const fetchStake = async (session) => {
    if (!session) return null;

    try {
        const contractKit = new ContractKit({ client: session.client });

        // Load the two contracts
        const reactor = await contractKit.load("pixelreactor");

        // Fetch balances
        const staked = await reactor.table("balance", "pixelreactor").get(session.actor);


        return {
            pinv: staked ? staked.pinv.value.toFixed(2) : '0.00', // Return '0.0000' if not found
            page: staked ? staked.page.value.toFixed(2) : '0.00' // Return '0.0000' if not found
        };

    } catch (error) {
        console.error('Error fetching balance:', error);
        return { pinv: '0.0000', page: '0.0000' }; // Return default values on error
    }
};