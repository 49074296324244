import React from 'react';
import "../widgets/css/Navbar.css";
const renderBalances = (balance) => {
    if (balance && typeof balance === 'object') {
        return (
            <>
                <div className="user-bals" style={{ marginBottom: '5px' }}>
                    <strong>WAX:</strong> {balance.wax || "0.0000"}

                    <strong>PINV:</strong> {balance.pinv || "0.0000"}

                    <strong>PAGE:</strong> {balance.page || "0.0000"}
                </div>
            </>
        );
    }

    return "0.0000 WAX"; // Default display if balance is not available
};

export default renderBalances;
