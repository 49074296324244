import React, { useEffect, useState, useCallback } from 'react';
import Navbar from './widgets/Navbar';
import './css/Token.css';
import { fetchStake } from './funcs/balFetch';

const Token = ({
    session,
    handleLogout,
    balance,
    transactionHash,
    setTransactionHash,
    setShowPopup,
    showPopup,
    handleAssetsRefresh
}) => {
    const [stake, setStake] = useState(null); // Initialize stake as null
    const [selectedToken, setSelectedToken] = useState('pinv'); // Default to 'pinv'
    const [amount, setAmount] = useState(''); // User input for amount
    const [error, setError] = useState(''); // Error message for validation
    const [action, setAction] = useState('stake'); // 'stake' or 'unstake'

    // Fetch stake data
    const getStake = useCallback(async () => {
        try {
            const stakes = await fetchStake(session);
            setStake(stakes); // Assuming stakes is an object or array
        } catch (error) {
            console.error('Failed to fetch stake:', error);
        }
    }, [session]);

    useEffect(() => {
        getStake(); // Only trigger this effect once when session changes
    }, [getStake]);

    useEffect(() => {
        if (showPopup) {
            const timer = setTimeout(() => {
                setShowPopup(false);
                handleAssetsRefresh(); // Re-fetch assets after popup closes
            }, 5000); // Popup disappears after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [handleAssetsRefresh, setShowPopup, showPopup]);

    // Handle token selection from dropdown
    const handleTokenChange = (e) => {
        setSelectedToken(e.target.value);
    };

    // Handle action (stake or unstake)
    const handleActionChange = (e) => {
        setAction(e.target.value);
    };

    // Handle input amount change
    const handleAmountChange = (e) => {
        setAmount(e.target.value);
        setError(''); // Reset error on each input change
    };


    // Handle form submission (staking/unstaking)
    const handleSubmit = async (e) => {
        console.log(e);
    };

    return (
        <div className="token-page">
            <Navbar handleLogout={handleLogout} session={session} balance={balance} />

            {stake ? (
                <div className="stakes">
                    <div className='token-info'>
                        <div className="headers">
                            <h2>
                                <select onChange={handleTokenChange} value={selectedToken}>
                                    <option value="pinv">PINV</option>
                                    <option value="page">PAGE</option>
                                </select>
                            </h2>

                            <p
                                onClick={() => {
                                    if (action === 'unstake') {
                                        setAmount(selectedToken === 'pinv' ? stake.pinv : stake.page);
                                    }
                                }}
                                style={{
                                    cursor: action === 'unstake' ? 'pointer' : 'not-allowed',
                                    color: action === 'unstake' ? 'blue' : 'gray',
                                    textDecoration: action === 'unstake' ? 'underline' : 'none',
                                }}
                                title={action === 'unstake' ? "Click to unstake all" : "Select 'Unstake' to enable"}
                            >
                                Staked: {selectedToken === 'pinv' ? stake.pinv : stake.page || "No stake data available"}
                            </p>

                            <p
                                onClick={() => {
                                    if (action === 'stake') {
                                        setAmount(selectedToken === 'pinv' ? balance.pinv : balance.page);
                                    }
                                }}
                                style={{
                                    cursor: action === 'stake' ? 'pointer' : 'not-allowed',
                                    color: action === 'stake' ? 'blue' : 'gray',
                                    textDecoration: action === 'stake' ? 'underline' : 'none',
                                }}
                                title={action === 'stake' ? "Click to stake all" : "Select 'Stake' to enable"}
                            >
                                In wallet: {selectedToken === 'pinv' ? balance.pinv : balance.page || "0.0000"}
                            </p>
                        </div>

                        <div className="form-container">
                            <div className="form-group">
                                <label htmlFor="action">Action</label>
                                <select id="action" onChange={handleActionChange} value={action}>
                                    <option value="stake">Stake</option>
                                    <option value="unstake">Unstake</option>
                                </select>
                            </div>

                            <div className="form-group">
                                <label htmlFor="amount">Amount</label>
                                <input
                                    id="amount"
                                    type="text"
                                    value={amount}
                                    onChange={handleAmountChange}
                                    placeholder="Enter amount"
                                />
                            </div>

                            {error && <div className="error-message">{error}</div>}

                            <button className="submit-button" onClick={handleSubmit}>
                                {action === 'stake' ? 'Stake' : 'Unstake'}
                            </button>
                        </div>
                    </div>


                </div>
            ) : (
                <p>Loading stake...</p> // Loading state
            )}

            {transactionHash && (
                <div className="transaction-link">
                    Transaction: <a href={`https://testnet.waxblock.io/transaction/${transactionHash}`} target="_blank" rel="noopener noreferrer">
                        {transactionHash.slice(0, 6)}...
                    </a>
                </div>
            )}
        </div>
    );
};

export default Token;
