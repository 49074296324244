import React, { useEffect, useState, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NFT from './NFT';
import Token from './Token';
import Login from './widgets/Login';
import { mainnetSessionKit, testnetSessionKit } from './authService';
import './css/App.css';
import { fetchBalance } from './funcs/balFetch';
import Popup from './widgets/Popup';
import { trackEvent } from '../analytics.js';

/*
// Toggle select all/deselect all
  const toggleSelectAll = () => {
    const visibleAssetIds = assets.slice(0, visibleAssetsCount).map(asset => asset.asset_id);

    if (selectedAssets.length === visibleAssetIds.length) {
      // Deselect all if all visible assets are selected
      setSelectedAssets([]);
    } else {
      // Select all visible assets up to BATCH_SIZE
      const newSelectedAssets = visibleAssetIds.slice(0, BATCH_SIZE);
      setSelectedAssets(newSelectedAssets);
    }
  };
*/
const App = () => {
  const [session, setSession] = useState(null); // No localStorage, just state
  const [network, setNetwork] = useState('mainnet'); // Default to mainnet
  const [balance, setBalance] = useState({ "pinv": 0, "page": 0 }); // Store balance in state
  const [assets, setAssets] = useState([]); // Store NFTs
  const [selectedAssets, setSelectedAssets] = useState([]); // Store selected NFTs
  const [schemaFilter, setSchemaFilter] = useState(null); // Store schema filter
  const [schemas, setSchemas] = useState([]); // Store available schemas
  const BATCH_SIZE = 50;
  const [visibleAssetsCount, setVisibleAssetsCount] = useState(20); // Pagination for assets
  const [transactionHash, setTransactionHash] = useState(''); // To hold the transaction hash for popup
  const [showPopup, setShowPopup] = useState(false); // For controlling the popup visibility
  // Function to fetch user assets and categorize them by schema
  const fetchAssets = useCallback(async (owner, page = 1, fetchedAssets = []) => {
    const collection = 'pixeltycoons';
    const response = await fetch(`https://wax.blokcrafters.io/atomicassets/v1/assets?collection_name=${collection}&owner=${owner}&page=${page}&limit=1000&order=desc&sort=asset_id`);
    const data = await response.json();

    const newAssets = fetchedAssets.concat(data.data);

    if (data.data.length < 1000) {
      const uniqueSchemas = [...new Set(newAssets.map(asset => asset.schema.schema_name))];
      setSchemas(uniqueSchemas); // Set available schemas


      return newAssets;
    }

    return fetchAssets(owner, page + 1, newAssets);
  }, []);

  // Restore session and balance on component mount
  const restoreSession = useCallback(async () => {
    try {
      let restoredSession;

      // Attempt to restore session based on network
      if (network === 'testnet') {
        restoredSession = await testnetSessionKit.restore();
      } else {
        restoredSession = await mainnetSessionKit.restore();
      }

      if (restoredSession) {
        trackEvent('restore_session', 'Restore Session', 'Connect Wallet', restoredSession.actor);
        setSession(restoredSession);

        // Fetch balance once session is restored
        const fetchedBalance = await fetchBalance(restoredSession);
        setBalance(fetchedBalance);

        // Fetch NFTs (assets)
        const fetchedAssets = await fetchAssets(restoredSession.actor);
        setAssets(fetchedAssets); // Store fetched assets
      }
    } catch (error) {
      console.error('Failed to restore session:', error);
    }
  }, [network, fetchAssets]);

  // Run on component mount or when session/network changes
  useEffect(() => {
    if (!session) {
      restoreSession(); // Restore session on app initialization
    }
  }, [session, restoreSession]);

  // Function to refresh assets after burn
  const handleAssetsRefresh = async () => {
    if (session) {
      const updatedAssets = await fetchAssets(session.actor);
      setAssets(updatedAssets); // Update the assets state with the new list
      setSelectedAssets([]); // Clear selected assets after burn
    }
  };

  const filteredAssets = schemaFilter
    ? assets.filter(asset => asset.schema.schema_name === schemaFilter)
    : assets;

  // Handle selecting and deselecting assets
  const handleSelectAsset = (assetId) => {
    setSelectedAssets(prevSelectedAssets => {
      if (prevSelectedAssets.includes(assetId)) {
        return prevSelectedAssets.filter(id => id !== assetId);
      } else {
        if (prevSelectedAssets.length >= BATCH_SIZE) {
          alert(`You can only select up to ${BATCH_SIZE} assets.`);
          return prevSelectedAssets;
        } else {
          return [...prevSelectedAssets, assetId];
        }
      }
    });
  };



  const handleLogout = async () => {
    trackEvent('logout', 'Logout Session', 'Disconnect Wallet', session.actor);
    if (network === 'testnet') {
      await testnetSessionKit.logout();
    } else {
      await mainnetSessionKit.logout();
    }
    // Reset session and balance
    setSession(null);
    setBalance(null);
  };

  return (
    <Router>
      <div className="App">
        {session ? (
          <>
            <Routes>
              <Route
                path="/"
                element={
                  <NFT
                    session={session}
                    handleLogout={handleLogout}
                    balance={balance}
                    setSchemaFilter={setSchemaFilter}
                    schemaFilter={schemaFilter}
                    filteredAssets={filteredAssets}
                    schemas={schemas}
                    onSelect={handleSelectAsset}
                    selectedAssets={selectedAssets}
                    assets={assets}
                    setTransactionHash={setTransactionHash}
                    setShowPopup={setShowPopup}
                    setSelectedAssets={setSelectedAssets}
                    refreshAssets={handleAssetsRefresh}
                    visibleAssets={visibleAssetsCount}
                    setVisibleAssets={setVisibleAssetsCount}
                  />
                }
              />
              <Route
                path="/nft/:filterType"
                element={
                  <NFT
                    session={session}
                    handleLogout={handleLogout}
                    balance={balance}
                    setSchemaFilter={setSchemaFilter}
                    schemaFilter={schemaFilter}
                    filteredAssets={filteredAssets}
                    schemas={schemas}
                    onSelect={handleSelectAsset}
                    selectedAssets={selectedAssets}
                    assets={assets}
                    setTransactionHash={setTransactionHash}
                    setShowPopup={setShowPopup}
                    setSelectedAssets={setSelectedAssets}
                    refreshAssets={handleAssetsRefresh}
                    visibleAssets={visibleAssetsCount}
                    setVisibleAssets={setVisibleAssetsCount}
                  />
                }
              />

              <Route
                path="/pixelreactor-staking"
                element={
                  <Token
                    session={session}
                    handleLogout={handleLogout}
                    balance={balance}
                    setTransactionHash={setTransactionHash}
                    setShowPopup={setShowPopup}

                  />
                }
              />

            </Routes>
          </>
        ) : (
          <>
            <h1 className='first-title'>Welcome to PixelTycoons!!</h1>
            <div className="login-and-gitbook">
              <Login
                className="Login"
                onLogin={(session) => {
                  setSession(session);
                  setNetwork('mainnet');
                }}
                setNetwork={setNetwork}
                fetchBalance={fetchBalance}
                fetchAssets={fetchAssets}
                setBalance={setBalance}
                setAssets={setAssets}
              />
            </div>
          </>
        )}

        {showPopup && (
          <Popup
            transactionLink={`https://waxblock.io/transaction/${transactionHash}`}
            onClose={() => setShowPopup(false)}
          />
        )}
      </div>
    </Router>
  );

};

export default App;